<template>
  <div class="backtestDados">
    <p class="disclaimer">
      Upload de Arquivo
      <span class="material-symbols-outlined helpIcon">
        help
        <md-tooltip class="md-layout-item md-size-33 md-small-size-66">
          Selecione o arquivo do Lote para a execução do Backtest <br />
          <strong
            >Atenção às instruções do Layout do cabeçalho esperado para o tipo
            de pessoa escolhido</strong
          >
        </md-tooltip>
      </span>
    </p>
    <UploadDadosBox
      @selectFile="selectFile"
      @readFile="readFile"
      :opcaoPessoaSelecionada="opcaoPessoaSelecionada"
    />
  </div>
</template>

<script>
import UploadDadosBox from "@/components/Inputs/UploadDadosBox";

export default {
  components: {
    UploadDadosBox,
  },

  props: {
    opcaoPessoaSelecionada: String,
  },

  data() {
    return {
      file: null,
      firstLine: "",
      secLine: "",
    };
  },
  methods: {
    selectFile(f) {
      this.file = f;
      this.$emit("selectFile", this.file);
    },

    readFile(t, s) {
      this.firstLine = t;
      this.secLine = s;
      this.$emit("readFile", this.firstLine, this.secLine);
    },
  },

  computed: {},

  watch: {},
  mounted() {
    this.file = null;
  },

  created() {
    if (!this.opcaoPessoaSelecionada) {
      window.location.href = "/backtest/nova/0";
    }
    this.file = null;
    this.resultadoLote = null;
    this.errorAlert = "";
  },
};
</script>

<style lang="scss" scoped>
.backtestDados {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
}
.disclaimer {
  font-weight: 500;
  font-size: 1.6rem;
  text-align: center;

  @media (min-width: 1025px) {
    font-size: 2.2rem;
  }
}

.helpIcon {
  cursor: pointer;
  color: #209cee;
  font-size: 1.5rem;
}
</style>