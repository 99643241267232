<template>
  <div class="inicial-container">
    <p class="disclaimer">Status de carregamento do Lote</p>
    <div class="field">
      <UploadDadosStatus
        :file="file"
        :firstLine="firstLine"
        :opcaoPessoaSelecionada="opcaoPessoaSelecionada"
        :resultadoLote="resultadoLote"
        :errorAlert="errorAlert"
      />
    </div>
  </div>
</template>

<script>
import UploadDadosStatus from "@/components/Inputs/UploadDadosStatus.vue";
export default {
  components: {
    UploadDadosStatus,
  },

  props: {
    file: File,
    firstLine: String,
    opcaoPessoaSelecionada: String,
    resultadoLote: Object,
    errorAlert: String,
  },

  data() {
    return {};
  },

  methods: {},

  created() {
    if (!this.file) {
      window.location.href = "/backtest/nova/0";
    }
  },
};
</script>

<style lang="scss" scoped>
.inicial-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.inicial-container .disclaimer {
  font-weight: 500;
  font-size: 1.6rem;
  text-align: center;
}

@media (min-width: 1025px) {
  .inicial-container .disclaimer {
    font-size: 2.2rem;
  }
}

.field {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  // height: 450px;

  // @include mediaQueryMax(medium) {
  //   height: 535px;
  // }
  // @include mediaQueryMax(small) {
  //   height: 550px;
  // }
}
</style>