var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inicial-container"},[_c('p',{staticClass:"disclaimer"},[_vm._v(" Selecione o tipo de backtest desejado "),_c('span',{staticClass:"material-symbols-outlined helpIcon",attrs:{"id":"helpIconSelecaoPessoa"}},[_vm._v(" help "),_c('md-tooltip',{staticClass:"md-layout-item md-size-33 md-small-size-66"},[_vm._v(" Selecione o tipo de pessoas utilizadas (CPF ou CNPJ) no Lote a ser executado. ")])],1)]),_c('div',{staticClass:"opcoes"},_vm._l((_vm.opcaoPessoa),function(opcao){return _c('div',{key:opcao.Nome},[(opcao.Disabled)?_c('md-tooltip',[_vm._v(" "+_vm._s(opcao.Nome)+" não disponível para este usuário ")]):_vm._e(),_c('div',{staticClass:"opcao",class:{
          opcaoInvalida: opcao.Disabled,
          opcaoValida: !opcao.Disabled,
          opcaoSelecionada: _vm.verificaSelecionada(opcao.Nome),
        },on:{"click":function($event){opcao.Disabled ? '' : _vm.emiteEvento(opcao.Nome)}}},[_c('span',{staticClass:"material-icons icone"},[_vm._v(_vm._s(opcao.Icone))]),_c('p',[_vm._v(_vm._s(opcao.Nome))])])],1)}),0),(_vm.liberaAlerta)?_c('div',{staticClass:"alertaPermissao"},[_vm._m(0),_vm._m(1)]):_vm._e(),(_vm.loading)?_c('div',[_c('Loading',{staticClass:"spinner"})],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iconAlert"},[_c('span',{staticClass:"material-symbols-outlined icon"},[_vm._v(" error ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"textAlert"},[_vm._v(" Usuário não tem acesso à execução de lotes. "),_c('br'),_vm._v(" Contate o "),_c('a',{attrs:{"href":"/suporte/contato"}},[_vm._v("Suporte")]),_vm._v(" para requisitar este acesso. ")])
}]

export { render, staticRenderFns }