<template>
  <div class="md-layout tableResult" v-if="tableDataLote">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-blue">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Histórico de Backtest</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <div class="md-layout boxLotes">
                <div class="selectInfoLotes">
                  <md-field
                    class="boxInfo md-layout-item md-size-35 md-medium-size-50 md-xsmall-size-100"
                  >
                    <label for="identificacaoLotes">Identificação</label>
                    <md-input
                      v-model="identificacaoLotes"
                      :disabled="nomeProjetoLotes != ''"
                      @keyup.enter="recuperaHistorico"
                    ></md-input>
                  </md-field>
                  <md-field
                    class="boxInfo md-layout-item md-size-35 md-medium-size-50 md-xsmall-size-100"
                  >
                    <label for="nomeProjetoLotes">Nome do Projeto</label>
                    <md-input
                      v-model="nomeProjetoLotes"
                      :disabled="identificacaoLotes != ''"
                      @keyup.enter="recuperaHistorico"
                    ></md-input>
                  </md-field>
                </div>
                <div class="selectInfoLotes">
                  <div
                    class="boxInfo md-layout-item md-size-25 md-medium-size-40 md-xsmall-size-100"
                  >
                    <md-field>
                      <label for="periodoPreDefinidoLotes"
                        >Período a analisar</label
                      >
                      <md-select
                        v-model="periodoPreDefinidoLotes"
                        name="periodoPreDefinidoLotes"
                        id="periodoPreDefinidoLotes"
                        :disabled="
                          identificacaoLotes != '' || nomeProjetoLotes != ''
                        "
                      >
                        <md-option value="diaAtual">Hoje</md-option>
                        <md-option value="semanaAtual">Esta semana</md-option>
                        <md-option value="mesAtual">Este mês</md-option>
                        <md-option value="semanaAnterior"
                          >Semana passada</md-option
                        >
                        <md-option value="mesAnterior">Mês passado</md-option>
                        <md-option value="custom">Customizado</md-option>
                      </md-select>
                    </md-field>
                  </div>

                  <div
                    class="boxInfo md-layout-item md-size-35 md-medium-size-50 md-xsmall-size-100"
                    v-if="periodoPreDefinidoLotes != 'custom'"
                  >
                    <md-field>
                      <md-input
                        disabled
                        :value="periodoDe + ' até ' + periodoAte"
                      ></md-input>
                    </md-field>
                  </div>
                  <div
                    class="boxInfo md-layout-item md-size-35 md-small-size-50 md-xsmall-size-100"
                    v-if="periodoPreDefinidoLotes == 'custom'"
                  >
                    <date-range-picker
                      class="selectPeriod"
                      @atualizouData="periodoFiltroAtualizado"
                      :periodoDe="periodoDeToDate()"
                      :periodoAte="periodoAteToDate()"
                    />
                  </div>
                </div>
                <div
                  class="md-layout-item md-size-50 md-medium-size-50 md-xsmall-size-100"
                  v-if="periodoPreDefinidoLotes == 'custom'"
                >
                  <span class="layout-warning"
                    >*Período máximo para o filtro Customizado é de 60 dias.
                  </span>
                </div>
                <div class="selectInfoLotes">
                  <md-field
                    class="selectStatus2 boxInfo md-layout-item md-size-25 md-small-size-40 md-xsmall-size-100"
                  >
                    <label for="statusPreDefinidoLotes"
                      >Status a analisar</label
                    >
                    <md-select
                      v-model="statusPreDefinidoLotes"
                      name="statusPreDefinidoLotes"
                      id="statusPreDefinidoLotes"
                      :disabled="
                        identificacaoLotes != '' || nomeProjetoLotes != ''
                      "
                    >
                      <md-option value="todos">Todos</md-option>
                      <span class="optionTitle">Estados finais</span>
                      <md-option
                        value="criado"
                        style="border-top: solid 1px #aaaaaa"
                        >Criado</md-option
                      >
                      <md-option value="iniciado">Iniciado</md-option>
                      <md-option value="pausado">Pausado</md-option>
                      <md-option value="cancelado">Cancelado</md-option>
                      <md-option value="terminado">Terminado</md-option>
                      <md-option value="erro">Erro</md-option>
                      <span class="optionTitle">Estados transitórios</span>
                      <md-option
                        value="iniciando"
                        style="border-top: solid 1px #aaaaaa"
                        >Iniciando</md-option
                      >
                      <md-option value="pausando">Pausando</md-option>
                      <md-option value="cancelando">Cancelando</md-option>
                      <md-option value="remonte">Remonte</md-option>
                    </md-select>
                  </md-field>
                  <md-field
                    class="boxInfo selectStatus2 md-layout-item md-size-35 md-medium-size-50 md-xsmall-size-100"
                    v-if="liberaFiltroNome"
                  >
                    <label for="identificacaoLotes">Usuário</label>
                    <md-input
                      v-model="pesquisaUsuario"
                      @keyup.enter="recuperaHistorico"
                    ></md-input>
                  </md-field>
                </div>
                <div
                  class="md-layout-item md-size-25 md-medium-size-50 md-xsmall-size-100 conteudo-vertical-centralizado conteudo-horizontal-direita"
                >
                  <md-button
                    class="md-primary md-sm"
                    :disabled="dadosFiltroCarregando"
                    @click.native.prevent="recuperaHistorico"
                    >Filtrar<md-icon>update</md-icon></md-button
                  >
                </div>
                <div
                  class="md-layout-item md-size-15 md-medium-size-50 md-xsmall-size-100 conteudo-vertical-centralizado conteudo-horizontal-direita"
                >
                  <md-progress-spinner
                    v-if="dadosFiltroCarregando"
                    :md-diameter="30"
                    :md-stroke="2"
                    md-mode="indeterminate"
                  >
                  </md-progress-spinner>
                  <small v-if="mensagemCarregamentoFiltro">
                    {{ mensagemCarregamentoFiltro }}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </md-card-content>
      </md-card>

      <md-card class="tableResult" id="tabela_historico" style="z-index: 0">
        <md-card-content>
          <md-card
            class="conteudo-horizontal-centralizado"
            v-if="tableDataLote.length == 0"
          >
            <md-card-content> Nenhum resultado encontrado. </md-card-content>
          </md-card>
          <ResultadoHistoricoLotes
            v-if="tableDataLote && tableDataLote.length != 0"
            :tableDataLote="tableDataLote"
            :counter="counter"
            :usuario="usuario"
          />
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import { DateRangePicker } from "@/components";
import { userService } from "@/services";
import { historicoService } from "@/services";
import { lotesStore } from "@/stores/lotes";
import { chatOutboxStore } from "@/stores/chat-Outbox.js";
import { mapWritableState, mapActions } from "pinia";

import ResultadoHistoricoLotes from "@/components/Historico/ResultadoHistoricoLotes.vue";

export default {
  components: {
    DateRangePicker,
    ResultadoHistoricoLotes,
  },
  computed: {
    ...mapWritableState(lotesStore, {
      historicoFiltrosLotes: "historicoFiltrosLotes",
      historicoResultadosLotes: "historicoResultadosLotes",
    }),

    filtroUsuario() {
      if (this.pesquisaUsuario.trim().length > 0) {
        const searchTerm = this.pesquisaUsuario.toLowerCase();
        return this.tableDataLote.filter((item) =>
          item.nome_usuario.toLowerCase().includes(searchTerm)
        );
      }
      return this.tableDataLote;
    },
  },

  data() {
    return {
      periodoDe: "",
      periodoAte: "",
      periodoPreDefinidoLotes: "",
      statusPreDefinidoLotes: "",
      identificacaoLotes: "",
      nomeProjetoLotes: "",
      total: 0,
      mensagemCarregamentoFiltro: "",
      dadosFiltroCarregando: false,
      identificacao_execucao: "",
      pct_completo: 0,
      status: "",
      tableDataLote: [],
      tableDataLoteKey: false,
      refreshValues: [],
      counter: 0,
      pesquisaUsuario: "",
      usuario: null,
      liberaFiltroNome: false,
    };
  },

  methods: {
    periodoDeToDate: function () {
      var resultado = this.$moment(this.periodoDe, "DD/MM/YYYY").toDate();
      return resultado;
    },
    periodoAteToDate: function () {
      var resultado = this.$moment(this.periodoAte, "DD/MM/YYYY").toDate();
      return resultado;
    },

    async periodoFiltroAtualizado(a) {
      this.periodoDe = a.periodoDe;
      this.periodoAte = a.periodoAte;
    },

    preenchePeriodoInicial() {
      this.periodoDe = this.$moment()
        .locale("pt-BR")
        .startOf("week")
        .format("DD/MM/YYYY");
      this.periodoAte = this.$moment().format("DD/MM/YYYY");
    },

    salvarEstado(objeto, resultado) {
      this.historicoFiltrosLotes = objeto;
      this.historicoResultadosLotes = resultado;
    },

    async recuperaHistorico() {
      this.dadosFiltroCarregando = true;
      this.tableDataLote = null;

      var periodoDeFormatado = this.$moment(
        this.periodoDe,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");

      var periodoAteFormatado = this.$moment(
        this.periodoAte,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");

      var statusLote = this.status;
      var identificLotes = this.identificacaoLotes;
      var nameLotes = this.nomeProjetoLotes;

      const objetoHistorico = {
        periodo_de_brt: periodoDeFormatado,
        periodo_ate_brt: periodoAteFormatado,
        status: statusLote,
        identificacao_execucao: identificLotes,
        nome_projeto: nameLotes,
      };
      const query = new URLSearchParams(objetoHistorico);
      this.tableDataLote = await historicoService.recuperaDadosHistoricoLotes(
        query.toString()
      );
      if (this.pesquisaUsuario.trim().length > 0) {
        this.tableDataLote = this.filtroUsuario;
      }

      //solução temporária
      setInterval(() => {
        this.dadosFiltroCarregando = false;
      }, 3000);

      objetoHistorico.total = this.total;
    },

    async recuperaDadosUsuario() {
      userService.recuperaDadosUsuarioLogado().then(
        (usuario) => {
          this.usuario = usuario;
          this.username = usuario.Usuario;
          this.grupos = usuario.Grupos;
          this.verificaPermissao();
        },
        (error) => {
          this.msgErroUsuario = "Erro ao carregar dados do usuário logado.";
        }
      );
    },

    verificaPermissao() {
      for (let i in this.grupos) {
        if (
          this.grupos[i] == "Administradores Gerais" ||
          this.grupos[i] == "Administradores de Lotes"
        ) {
          this.liberaFiltroNome = true;
        }
      }
    },

    ...mapActions(chatOutboxStore, ["recebeContexto"]),
  },

  async created() {
    const contexto = {
      codigo_agrupador: "",
      rota: this.$route.path,
      nome: this.$route.name,
      descricao: "",
    };

    this.recebeContexto(contexto);

    await this.recuperaDadosUsuario();

    this.analytics(
      "Lista Histórico",
      "Acessos",
      "Página inicial de históricos acessada."
    );
    if (Object.keys(this.historicoFiltrosLotes).length == 0) {
      this.periodoPreDefinidoLotes = "semanaAtual";
      this.statusPreDefinidoLotes = "todos";

      this.periodoDe = this.$moment()
        .locale("pt-BR")
        .startOf("week")
        .format("DD/MM/YYYY");
      this.periodoAte = this.$moment().format("DD/MM/YYYY");
      this.identificacaoLotes = "";
      this.nomeProjetoLotes = "";
      await this.recuperaHistorico();
      this.dadosFiltroCarregando = false;
    } else {
      this.dadosFiltroCarregando = true;
      this.periodoPreDefinidoLotes =
        this.historicoFiltrosLotes.periodoPreDefinidoLotes;
      this.statusPreDefinidoLotes =
        this.historicoFiltrosLotes.statusPreDefinidoLotes;
      this.identificacaoLotes = this.historicoFiltrosLotes.identificacaoLotes;
      this.nomeProjetoLotes = this.historicoFiltrosLotes.nomeProjetoLotes;
      this.total = this.historicoFiltrosLotes.total;
      this.tableDataLote = this.historicoResultadosLotes;

      this.dadosFiltroCarregando = false;
    }
  },

  destroyed() {
    clearInterval(this.counter);
    this.identificacaoLotes = "";
    this.nomeProjetoLotes = "";
  },

  watch: {
    async periodoPreDefinidoLotes(periodoSelecionado) {
      this.analytics(
        `Troca de periodo pré definido para ${periodoSelecionado}`,
        "Troca de variável",
        "Houve uma troca no periodo selecionado do histórico"
      );

      switch (periodoSelecionado) {
        case "mesAtual":
          this.periodoDe = "01/" + this.$moment().format("MM/YYYY");
          this.periodoAte = this.$moment().format("DD/MM/YYYY");

          break;
        case "mesAnterior":
          var dataMesAnterior = this.$moment()
            .locale("pt-BR")
            .subtract({ months: 1 });
          this.periodoDe = "01/" + dataMesAnterior.format("MM/YYYY");
          this.periodoAte = dataMesAnterior.endOf("month").format("DD/MM/YYYY");

          break;
        case "semanaAtual":
          this.periodoDe = this.$moment()
            .locale("pt-BR")
            .startOf("week")
            .format("DD/MM/YYYY");
          this.periodoAte = this.$moment().format("DD/MM/YYYY");

          break;
        case "semanaAnterior":
          var dataSemanaAnterior = this.$moment()
            .locale("pt-BR")
            .subtract({ weeks: 1 });
          this.periodoDe = dataSemanaAnterior
            .startOf("week")
            .format("DD/MM/YYYY");
          this.periodoAte = dataSemanaAnterior
            .endOf("week")
            .format("DD/MM/YYYY");

          break;
        case "diaAtual":
          this.periodoDe = this.$moment().format("DD/MM/YYYY");
          this.periodoAte = this.$moment().format("DD/MM/YYYY");

          break;

        case "custom":
          break;
      }
    },

    async statusPreDefinidoLotes(statusSelecionado) {
      switch (statusSelecionado) {
        case "todos":
          this.status = "Todos";
          break;
        case "criado":
          this.status = "Criado";
          break;
        case "iniciado":
          this.status = "Iniciado";
          break;
        case "pausado":
          this.status = "Pausado";
          break;
        case "cancelado":
          this.status = "Cancelado";
          break;
        case "iniciando":
          this.status = "Iniciando";
          break;
        case "pausando":
          this.status = "Pausando";
          break;
        case "cancelando":
          this.status = "Cancelando";
          break;
        case "terminado":
          this.status = "Terminado";
          break;
        case "erro":
          this.status = "Erro";
          break;
        case "remonte":
          this.status = "Remonte";
          break;
      }
    },

    async identificacaoLotes(infoSelecionada) {
      this.identificacaoLotes = infoSelecionada;
    },
  },
};
</script>

<style lang="scss" scoped>
.tableResult {
  z-index: 0;
}
.selectStatus {
  margin-left: 15px;
  margin-right: 20%;
  width: 20%;
}

.selectStatus2 {
  margin-right: 5%;
  width: 40%;
}

.boxLotes {
  display: flex;
  flex-direction: column;
}

.boxInfo {
  margin: 10px 30px 0 0;
  padding: 5px 0;
}
.selectInfoLotes {
  display: flex;
  justify-content: flex-start;
  margin-left: 15px;
  width: 70%;

  @include mediaQueryMax(small) {
    flex-direction: column;
    width: 90%;
  }
}

.optionTitle {
  font-size: 12px;
  color: #aaaaaa;
  margin-left: 5px;
  user-select: none;
  cursor: default;
}

.layout-warning {
  position: relative;
  bottom: 15px;
  font-size: 11px;
  opacity: 0.5;
}
</style>
