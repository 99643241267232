<template>
  <div class="inicial-container">
    <p class="disclaimer">
      Estrutura do arquivo
      <span class="material-symbols-outlined helpIcon" id="helpIconMapeamento">
        help
        <md-tooltip class="md-layout-item md-size-33 md-small-size-66">
          Identificação do separador do cabeçalho do arquivo.
          <br />
          Selecionar qual o <strong>separador</strong> do cabeçalho do arquivo
          de Lote selecionado. <br />

          <strong
            >Caso seja escolhido um separador do cabeçalho errado, as colunas
            disponibilizadas para comparação com o layout da Outbox estarão
            incorretas</strong
          >
        </md-tooltip>
      </span>
    </p>
    <EstruturaArquivo
      :firstLine="firstLine"
      :secondLine="secondLine"
      @recebeInfoCabecalho="recebeInfoCabecalho"
      @recebeColunas="recebeColunas"
    />
  </div>
</template>

<script>
import EstruturaArquivo from "@/components/Inputs/EstruturaArquivo.vue";

export default {
  components: {
    EstruturaArquivo,
  },

  props: {
    file: File,
    firstLine: String,
    secondLine: String,
    opcaoPessoaSelecionada: String,
  },

  data() {
    return {
      separadorDefinido: "",
      fileColuns: [],
      finalHeader: "",
      finalFormat: "",
    };
  },

  methods: {
    recebeInfoCabecalho(h, f) {
      this.finalHeader = h;
      this.finalFormat = f;
      this.$emit("recebeInfoCabecalho", this.finalHeader, this.finalFormat);
    },
    recebeColunas(c, s) {
      this.fileColuns = c;
      this.separadorDefinido = s;
      this.$emit("recebeColunas", this.fileColuns, this.separadorDefinido);
    },
  },

  created() {
    if (!this.opcaoPessoaSelecionada || !this.file) {
      window.location.href = "/backtest/nova/0";
    }
  },
};
</script>

<style lang="scss" scoped>
.inicial-container {
  display: flex;
  flex-direction: column;
}

.inicial-container .disclaimer {
  font-weight: 500;
  font-size: 1.6rem;
  text-align: center;
}

@media (min-width: 1025px) {
  .inicial-container .disclaimer {
    font-size: 2.2rem;
  }
}
.helpIcon {
  cursor: pointer;
  color: #209cee;
  font-size: 1.5rem;
}
</style>
