<template>
  <div class="inicial-container">
    <p class="disclaimer">
      Resumo do Lote a submeter
      <span class="material-symbols-outlined helpIcon">
        help
        <md-tooltip class="md-layout-item md-size-33 md-small-size-66">
          Revisão das informações do arquivo enviado e dos mapeamentos
          realizados <br />
          Deve ser checado se as colunas mapeadas são correspondentes. <br />
          <strong>
            Alterações nos passos anteriores podem ocorrer caso o usuário ache
            necessário. <br />
          </strong>
          Colunas de documentos, cep, datas e decimais terão as linhas validadas
          e um gráfico será gerado informando quanto dos dados inseridos são
          validos ou inválidos. <br />
          <strong>
            A partir daqui o Lote poderá ser Criado, Criado e Executado ou
            Cancelado.
          </strong>
        </md-tooltip>
      </span>
    </p>
    <div class="field">
      <UploadDadosResume
        @resultadoStatus="resultadoStatus"
        @showErrorStatus="showErrorStatus"
        @resetResume="resetResume"
        :file="file"
        :opcaoPessoaSelecionada="opcaoPessoaSelecionada"
        :fileColuns="fileColuns"
        :separadorDefinido="separadorDefinido"
        :finalHeader="finalHeader"
        :finalFormat="finalFormat"
        :projectName="projectName"
        :usuario="usuario"
      />
    </div>
  </div>
</template>

<script>
import UploadDadosResume from "@/components/Inputs//UploadDadosResume.vue";

export default {
  components: {
    UploadDadosResume,
  },

  props: {
    file: File,
    fileColuns: Array,
    opcaoPessoaSelecionada: String,
    separadorDefinido: String,
    finalHeader: String,
    finalFormat: String,
    projectName: String,
    usuario: Object,
  },

  data() {
    return {
      resultadoLote: null,
      errorAlert: "",
    };
  },

  methods: {
    resultadoStatus(res) {
      this.resultadoLote = res;
      this.$emit("resultadoStatus", this.resultadoLote);
    },
    showErrorStatus(res) {
      this.errorAlert = res;
      this.$emit("showErrorStatus", this.errorAlert);
    },
    resetResume() {
      this.$emit("resetResume");
    },
  },

  created() {
    if (!this.opcaoPessoaSelecionada || !this.file) {
      window.location.href = "/backtest/nova/0";
    }
  },
};
</script>

<style lang="scss" scoped>
.inicial-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.inicial-container .disclaimer {
  font-weight: 500;
  font-size: 1.6rem;
  text-align: center;
}

@media (min-width: 1025px) {
  .inicial-container .disclaimer {
    font-size: 2.2rem;
  }
}

.helpIcon {
  cursor: pointer;
  color: #209cee;
  font-size: 1.5rem;
}

.field {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
</style>
