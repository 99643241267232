import { render, staticRenderFns } from "./selecaoPessoa.vue?vue&type=template&id=45fd0351&scoped=true&"
import script from "./selecaoPessoa.vue?vue&type=script&lang=js&"
export * from "./selecaoPessoa.vue?vue&type=script&lang=js&"
import style0 from "./selecaoPessoa.vue?vue&type=style&index=0&id=45fd0351&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45fd0351",
  null
  
)

export default component.exports