<template>
  <div class="md-layout">

    <div class="md-layout-item md-small-size-100">

    </div>

  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  },
  beforeDestroy() {
  }
};
</script>
