import Notifications from "@/components/NotificationPlugin";
import { ValidationProvider, extend } from 'vee-validate';
import { required, email, confirmed, min, max } from 'vee-validate/dist/rules';

import SideBar from "./components/SidebarPlugin";
import VueMaterial from "vue-material";
import VueHtml2Canvas from 'vue-html2canvas';
import VueSvgGauge from 'vue-svg-gauge'
// import DropDown from "./components/Dropdown.vue";
import IconeProduto from "@/components/Customizados/IconeProduto";
import { directive as vClickOutside } from "vue-clickaway";

import "vue-material/dist/vue-material.min.css";
import "./assets/scss/material-dashboard.scss";

import "es6-promise/auto";

// filtros personalizados
import { toDecimalPtbr, toMoedaPtbr, toInteiroPtbr, toDataPtbr, toDataHoraPtbr, toDataHoraPtbrMenos3h } from './filtros';
import { validadorCep, validadorCpf, validadorCnpj, validadorDataPtbr,  validadorDataYMD} from '@/validadores';

// momment.js
const moment = require('moment');
require('moment/locale/br');


var VueScrollTo = require('vue-scrollto');
const vueScrollToOptions = {
     duration: 500,
     easing: "ease",
     offset: 0,
     force: true,
     cancelable: true,
     onStart: false,
     onDone: false,
     onCancel: false,
     x: false,
     y: true
 };


extend('ValidadorCep', { validate: value => validadorCep.valida(value)});
extend('ValidadorCpf', { validate: value => validadorCpf.valida(value)});
extend('ValidadorCnpj', { validate: value => validadorCnpj.valida(value)});
extend('ValidadorDataPtbr', { validate: value => validadorDataPtbr.valida(value)});
extend('validadorDataYMD', { validate: value => validadorDataYMD.valida(value)});
extend('required', required);
extend('email', email);
extend('confirmed', confirmed);
extend('min', min);
extend('max', max);


export default {
  install(Vue) {
    // Vue.component("drop-down", DropDown);
    Vue.component("icone-produto", IconeProduto);
    Vue.directive("click-outside", vClickOutside);
    Vue.use(VueScrollTo, vueScrollToOptions);
    Vue.use(VueMaterial);
    Vue.use(SideBar);
    Vue.use(Notifications);
    Vue.use(require('vue-moment'), { moment });
    Vue.component('ValidationProvider', ValidationProvider);
    Vue.filter('toDecimalPtbr', toDecimalPtbr);
    Vue.filter('toMoedaPtbr', toMoedaPtbr);
    Vue.filter('toInteiroPtbr', toInteiroPtbr);
    Vue.filter('toDataPtbr', toDataPtbr);
    Vue.filter('toDataHoraPtbr', toDataHoraPtbr);
    Vue.filter('toDataHoraPtbrMenos3h', toDataHoraPtbrMenos3h);
    Vue.use(VueHtml2Canvas);
    Vue.use(VueSvgGauge);
  }
};
