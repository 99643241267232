<template>
  <div class="inicial-container">
    <p class="disclaimer">
      Selecione o tipo de backtest desejado
      <span
        class="material-symbols-outlined helpIcon"
        id="helpIconSelecaoPessoa"
      >
        help
        <md-tooltip class="md-layout-item md-size-33 md-small-size-66">
          Selecione o tipo de pessoas utilizadas (CPF ou CNPJ) no Lote a ser
          executado.
        </md-tooltip>
      </span>
    </p>
    <div class="opcoes">
      <div v-for="opcao in opcaoPessoa" :key="opcao.Nome">
        <md-tooltip v-if="opcao.Disabled">
          {{ opcao.Nome }} não disponível para este usuário
        </md-tooltip>
        <div
          class="opcao"
          @click="opcao.Disabled ? '' : emiteEvento(opcao.Nome)"
          :class="{
            opcaoInvalida: opcao.Disabled,
            opcaoValida: !opcao.Disabled,
            opcaoSelecionada: verificaSelecionada(opcao.Nome),
          }"
        >
          <span class="material-icons icone">{{ opcao.Icone }}</span>
          <p>{{ opcao.Nome }}</p>
        </div>
      </div>
    </div>
    <div class="alertaPermissao" v-if="liberaAlerta">
      <div class="iconAlert">
        <span class="material-symbols-outlined icon"> error </span>
      </div>
      <div class="textAlert">
        Usuário não tem acesso à execução de lotes. <br />
        Contate o <a href="/suporte/contato">Suporte</a> para requisitar este
        acesso.
      </div>
    </div>
    <div v-if="loading">
      <Loading class="spinner" />
    </div>
  </div>
</template>

<script>
import { userService } from "@/services";
import Loading from "@/components/Loading";

export default {
  components: {
    Loading,
  },

  props: {
    opcaoPessoaSelecionada: String,
    produto: Object,
    produtosLotes: Array,
    projectName: String,
  },
  data() {
    return {
      opcaoPessoa: [
        {
          Nome: "Pessoa Física",
          Icone: "face",
          Disabled: true,
        },
        {
          Nome: "Pessoa Jurídica",
          Icone: "business",
          Disabled: true,
        },
      ],

      username: "",
      nomeAmigavel: "",
      empresa: "",
      grupos: [],

      loading: true,
      liberaAlerta: true,
    };
  },
  methods: {
    verificaSelecionada(nome) {
      if (nome == this.opcaoPessoaSelecionada) {
        return true;
      }
    },
    emiteEvento(opcao) {
      this.analytics(
        `${opcao} selecionada`,
        "Cliques de seleção",
        `Foi selecionada a opção ${opcao} na tela inicial de consulta`
      );
      this.$emit("selecionaInicial", opcao);
      this.$router.push("/backtest/nova/2");
    },

    async recuperaDadosUsuario() {
      userService.recuperaDadosUsuarioLogado().then(
        (usuario) => {
          this.$emit("recuperaUsuario", usuario);
          this.username = usuario.Usuario;
          this.nomeAmigavel = usuario.NomeAmigavel;

          this.empresa = usuario.Empresa;
          this.grupos = usuario.Grupos;

          this.verificaPermissoes();
        },
        (error) => {
          this.mensagemErroCarregamento =
            "Erro ao carregar dados do usuário logado.";
        }
      );
    },

    verificaPermissoes() {
      (this.loading = false), (this.opcaoPessoa[0].Disabled = true);
      this.opcaoPessoa[1].Disabled = true;
      this.grupos.forEach((grupo) => {
        if (grupo == "Executores de Lotes") {
          this.liberaAlerta = false;
              this.opcaoPessoa[0].Disabled = false;
              this.opcaoPessoa[1].Disabled = false;
        }
      });
    },
  },
  mounted() {
    this.recuperaDadosUsuario();
    this.analytics(
      "Seleção inicial",
      "Acesso",
      "Entrou na primeira página de preenchimento de uma nova consulta"
    );
  },

  created() {
    if (!this.projectName) {
      window.location.href = "/backtest/nova/0";
    }
  },
};
</script>

<style scoped>
.inicial-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.inicial-container .disclaimer {
  font-weight: 500;
  font-size: 1.6rem;
  text-align: center;
}
.opcoes {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 60px;
}
.opcao {
  font-weight: 400;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  gap: 0 30px;
}

.inicial-container .icone {
  font-size: 4rem;
}
.opcaoValida {
  cursor: pointer;
}

.opcaoInvalida {
  cursor: initial;
  opacity: 0.4;
}

.opcaoSelecionada {
  color: var(--accent);
}

.alertaPermissao {
  display: flex;
  flex-direction: row;
  align-self: end;
  width: 40%;
  padding: 1%;
  border: 0.1px dashed #c4c4c4;
  color: #777777;
}

.textAlert {
  margin-left: 3%;
}
.iconAlert {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 196, 0);
  height: 48px;
  width: 36px;
}

.icon {
  font-size: 36px;
}
.helpIcon {
  cursor: pointer;
  color: #209cee;
  font-size: 1.5rem;
}
.spinner {
  /* width: 20px; */
  position: absolute;
  left: 40%;
  top: 40%;
}

@media (min-width: 1025px) {
  .inicial-container .disclaimer {
    font-size: 2.2rem;
  }
  .opcoes {
    flex-direction: row;
  }
  .opcao {
    font-size: 1.8rem;
    flex-direction: column;
  }
  .inicial-container .icone {
    font-size: 5rem;
    transition: 0.2s ease;
  }
  .opcaoValida:hover > .icone {
    transform: translateY(-10px);
    color: var(--accent);
  }
  .opcaoValida:hover {
    color: var(--accent);
  }
}

@media (max-width: 1024px) {
  .animate1 {
    animation: reduz1 0.5s forwards;
  }
  .animate2 {
    animation: reduz2 0.5s forwards;
  }
  .animate3 {
    animation: reduz3 0.5s forwards;
  }
}

@keyframes reduz1 {
  0% {
    transform: translateY(120px) translateX(-70px);
    font-size: 300%;
  }
  100% {
    transform: translateY(-10px) translateX(0);
    font-size: 100%;
  }
}

@keyframes reduz2 {
  0% {
    transform: translateY(220px) translateX(-70px);
    font-size: 300%;
  }
  100% {
    transform: translateY(-10px) translateX(0);
    font-size: 100%;
  }
}

@keyframes reduz3 {
  0% {
    transform: translateY(350px) translateX(-100px);
    font-size: 300%;
  }
  100% {
    transform: translateY(-10px) translateX(0);
    font-size: 100%;
  }
}

@media (min-width: 1024px) {
  .animate1 {
    animation: reduz1-desk 0.7s forwards;
  }
  .animate2 {
    animation: reduz2-desk 0.7s forwards;
  }
  .animate3 {
    animation: reduz3-desk 0.7s forwards;
  }
}

@keyframes reduz1-desk {
  0% {
    transform: translateY(180px) translateX(-100px);
    font-size: 300%;
  }
  100% {
    transform: translateY(0);
    font-size: 100%;
  }
}

@keyframes reduz2-desk {
  0% {
    transform: translateY(180px) translateX(0);
    font-size: 300%;
  }
  100% {
    transform: translateY(0);
    font-size: 100%;
  }
}

@keyframes reduz3-desk {
  0% {
    transform: translateY(180px) translateX(200px);
    font-size: 300%;
  }
  100% {
    transform: translateY(0);
    font-size: 100%;
  }
}
</style>