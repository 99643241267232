<template>
  <div class="page">
    <Dropdown versao="2.5.1" :open="true">
      <main class="novidades novidades--minima">
        <section>
          <p class="titulo">
            <span class="material-symbols-outlined"> recent_actors </span>

            Trilha de consultas
          </p>
          <span class="texto">
            Agora, consultas adicionais estão disponíveis nas consultas
            <strong>Superbox</strong> e de <strong>Modelos</strong>, permitindo
            que o usuário armazene e navegue entre diferentes consultas, criando
            sua própria <strong>Trilha de Consultas</strong>.
          </span>
        </section>
        <section>
          <span class="texto">
            Com isso, a navegação entre consultas realizadas tornou-se mais
            dinâmica, facilitando para os usuários que realizam múltiplas
            consultas alternarem facilmente entre os resultados já obtidos.
          </span>
          <br /><br />
          <span class="texto">
            Essa mudança foi projetada para oferecer uma experiência mais fluida
            e eficiente, permitindo a comparação de resultados em um único
            contexto.
          </span>
        </section>
      </main>
    </Dropdown>
    <Dropdown versao="2.5.0" :open="false">
      <main class="novidades novidades--minima">
        <section>
          <p class="titulo">
            <img
              class="logo superbox"
              src="/img/superbox/logo-superbox.png"
              alt=""
            />
            Nova Consulta Superbox
          </p>
          <span class="texto">
            O Superbox é o principal compêndio de atributos relativos à Pessoa
            Física, caracterizando a essência do indivíduo consultado em
            espectros sociais, econômicos, empresariais e de geolocalidade. Este
            produto sofisticado redefine o futuro da análise de dados e da
            inteligência preditiva.
          </span>
          <span class="texto">
            <br /><br />
            Aborda padrões comportamentais, mapeamento de riscos e
            oportunidades, bem como apontamentos direcionados a partir de
            alertas.
          </span>
        </section>
        <section>
          <span class="texto">
            Construído a partir de uma vasta gama de variáveis e produtos de
            prateleira, o Superbox oferece um dossiê completo que inclui:
          </span>
          <div class="cols">
            <span class="col">
              <ul>
                <li>Dados Cadastrais;</li>
                <li>Renda Presumida;</li>
                <li>Associações Pessoais;</li>
                <li>Endereços e Geolocalidades;</li>
              </ul>
            </span>
            <span class="col">
              <ul>
                <li>Mapa Geral de Riscos;</li>
                <li>Histórico de Passagens;</li>
                <li>Conexões com Empresas;</li>
                <li>Alertas Gerais.</li>
              </ul>
            </span>
          </div>
          <span class="texto">
            Receba um dossiê completo de um indivíduo e impulsione suas decisões
            massificadas com essa poderosa ferramenta de analytics.
          </span>
        </section>
      </main>
    </Dropdown>
    <Dropdown versao="2.4.0" :open="false">
      <main class="novidades novidades--minima">
        <section>
          <p class="titulo">
            <span class="material-symbols-outlined"> bolt </span>
            Reestruturação do Portal Outbox
          </p>
          <span class="texto">
            Atualização da aparência do portal, com muitas novidades.
          </span>
          <ul>
            <li>Nova estrutura visual que permeia todo o Portal Outbox;</li>
            <li>
              Nova página inicial, com
              <strong>histórico de consultas</strong> realizadas e notícias da
              Outbox;
            </li>
            <li>
              Nova página para <strong>consulta de modelos</strong>,
              contemplando consultas de prateleira e customizadas;
            </li>
          </ul>
        </section>
        <section style="border-bottom: 0">
          <li>
            Nova jornada na página de <strong>consulta de Boxes</strong>, com
            lista de consultas disponíveis simplificada;
          </li>
          <li>
            Nova preferência de <strong>"parâmetros favoritos"</strong>,
            facilitando o preenchimento de parâmetros consultados
            recorrentemente pelo usuário;
          </li>
          <li>
            Nova jornada na página de <strong>backtest</strong>, com um novo
            passo que indica uma previsão do arquivo a ser enviado antes do
            mapeamento das colunas de entrada;
          </li>
          <li>
            Ajustes no Perfil de Negócios PJ para utilizar uma nova versão do
            <strong>Faturamento Presumido</strong>.
          </li>
        </section>
      </main>
    </Dropdown>
    <Dropdown versao="2.3.3">
      <main class="novidades novidades--minima">
        <section>
          <p class="titulo">
            <span class="material-symbols-outlined"> privacy_tip </span>
            Melhorias na Segurança
          </p>
          <ul>
            <li>
              Alteração em endereços das API de Conta, Suporte e Preferências;
            </li>
            <li>
              Melhorias nas mensagens de erro para usuários com falhas no
              acesso.
            </li>
          </ul>
        </section>
        <section style="border-bottom: 0">
          <p class="titulo">
            <span class="material-symbols-outlined">
              settings_accessibility
            </span>
            Melhorias na experiência do usuário
          </p>
          <ul>
            <li>
              Alterações visuais na tela de lotes para indicar de maneira mais
              efetivas as linhas processadas com erro;
            </li>
            <li>
              Possibilidade do usuário escolher no login se deseja ou não se
              manter conectado após o fechamento da janela do navegador.
            </li>
          </ul>
        </section>
      </main>
    </Dropdown>
    <Dropdown versao="2.3.2">
      <main class="novidades novidades--minima">
        <section>
          <p class="titulo">
            <span class="material-symbols-outlined">credit_score</span>
            Novos tipos de consulta no Backtest
          </p>
          <span class="texto">
            Agora é possível realizar novos tipos de consulta de Lotes. As
            consultas estão divididas entre:
          </span>
          <ul>
            <li>
              <strong>Box de atributos:</strong> são agrupamentos de informações
              encontradas em books ou modelos Outbox.
            </li>
            <li>
              <strong>Box de modelos:</strong> são os resultados de
              processamentos de modelos com uma finalidade específica.
            </li>
          </ul>
          <br />
          Para acessar esta funcionalidade,
          <router-link to="/backtest/nova/0">[clique aqui]</router-link> ou
          navegue pelo menu "Backtest" / "Nova".
        </section>
        <section style="border-bottom: 0">
          <p class="titulo">
            <span class="material-symbols-outlined">library_books</span>
            Nova documentação de Books Outbox
          </p>
          Atualizamos o layout da documentação de books para uma interface muito
          mais amigável, permitindo a visualização completa das características
          de um Book e contando com a busca em seus atributos.
          <br />
          <br />
          Para acessar esta funcionalidade
          <router-link to="/books">[clique aqui]</router-link> ou navegue pelo
          menu "Documentação de Books",
        </section>
      </main>
    </Dropdown>
    <Dropdown versao="2.3.1">
      <main class="novidades novidades--minima">
        <section>
          <p class="titulo">
            <span class="material-symbols-outlined">rocket_launch </span>
            Nova ferramenta: Integração com
            <img class="logo" src="img/Skynet.png" alt="" />
          </p>
          <span class="texto">
            Adicionamos a integração entre lotes finalizados e a plataforma
            SKYNET, com as seguintes opções:
          </span>
          <ul>
            <li>
              Configurações dinâmicas de variáveis, com representação gráfica de
              representatividades e eventos.
            </li>
            <li>
              Geração de relatório completo do modelo Fast Model do SKYNET.
            </li>
          </ul>
          <br />
          Para acessar esta funcionalidade navegue pelo menu "Backtest" /
          "Histórico" ou
          <router-link to="/backtest/historico_backtest"
            >clique aqui</router-link
          >, selecionando pelo menu "Ações" o Lote desejado para a Integração.
        </section>
        <section style="border-bottom: 0">
          <p class="titulo">
            <span class="material-symbols-outlined">cycle</span>Histórico de
            submissão de Integrações
          </p>
          Acompanhamento e download das Integrações realizadas para cada Lote
          executado.
          <br />
          <br />

          Para acessar esta funcionalidade navegue pelo menu "Backtest" /
          "Histórico" ou
          <router-link to="/backtest/historico_backtest"
            >clique aqui</router-link
          >, selecionando o Lote que deseja visualizar as Integrações
          realizadas.
        </section>
      </main>
    </Dropdown>
    <Dropdown versao="2.3.0">
      <main class="novidades novidades--minima">
        <section>
          <p class="titulo">
            <span class="material-symbols-outlined">post_add</span>Nova página:
            Backtest
          </p>
          <ul>
            <li>
              Upload do arquivo de lotes, para pessoas físicas e jurídicas.
            </li>
            <li>
              Mapeamento do cabeçalho, baseado no layout solicitado pela Outbox.
            </li>
            <li>Mapeamento dos formatos de data.</li>
            <li>Visualização do status do arquivo após upload.</li>
          </ul>
          <br />
          Para acessar esta funcionalidade,
          <router-link to="/backtest/nova/0">[clique aqui]</router-link> ou
          navegue pelo menu "Backtest" / "Nova"
        </section>
        <section style="border-bottom: 0">
          <p class="titulo">
            <span class="material-symbols-outlined">history</span>Histórico de
            carregamento de Lotes
          </p>
          Criação, inicio, pausa e cancelamento de carregamento de Lotes e
          visualização do progresso de carregamento.

          <br /><br />
          Para acessar esta funcionalidade,
          <router-link to="/backtest/historico_backtest"
            >[clique aqui]</router-link
          >
          ou navegue pelo menu "Backtest" / "Histórico"
        </section>
      </main>
    </Dropdown>
    <Dropdown versao="2.2.1">
      <main class="novidades novidades--minima">
        <section>
          <p class="titulo">
            <span class="material-symbols-outlined">bug_report</span>Correção de
            bugs
          </p>
          <ul>
            <li>Correção de bugs no permissionamento de acesso as páginas.</li>
            <li>Correção de bug nos mapas.</li>
            <li>Correção de permissão nas preferências de usuário.</li>
            <li>Correção de permissão nos alertas.</li>
          </ul>
        </section>
        <section style="border-bottom: 0">
          <p class="titulo">
            <span class="material-symbols-outlined">timeline</span>Manutenção do
            contexto de histórico
          </p>
          Ao utilizar um filtro ou a paginação na página de histórico, ao
          acessar uma consulta e retornar o contexto é mantido (página e
          resultados).
        </section>
      </main>
    </Dropdown>
    <Dropdown versao="2.2.0">
      <main class="novidades novidades--minima">
        <section style="border-bottom: 0">
          <p class="titulo">
            <span class="material-symbols-outlined">recycling</span>Nova página:
            Documentação dos Books
          </p>
          Agora é possível visualizar em tempo real a descrição de todos os mais
          de 5 mil atributos disponíveis no Book de produtos Outbox.
          <br /><br />Para acessar esta funcionalidade
          <router-link to="/books">[clique aqui]</router-link> ou navegue pelo
          menu "Documentação de Books",
        </section>
      </main>
    </Dropdown>

    <Dropdown versao="2.1.0">
      <main class="novidades novidades--minima">
        <section>
          <p class="titulo">
            <span class="material-symbols-rounded">star</span>Preferência de
            produtos favoritos
          </p>
          <p>
            Possibilidade de marcar produtos como favoritos durante a seleção de
            produtos em "Nova Consulta" e através do menu de preferências
            <router-link to="/preferencias">[clique aqui]</router-link> no menu
            de usuário
          </p>
        </section>
        <section style="border-bottom: 0">
          <p class="titulo">
            <span class="material-symbols-outlined">recycling</span>Adicionar
            produtos a consulta
          </p>
          Agora, na tela de resultados de uma consulta existe a opção de
          adicionar consultas não selecionadas no momento inicial de seleção,
          utilizando os mesmos parâmetros da consulta atual.
        </section>
      </main>
    </Dropdown>

    <Dropdown versao="2.0.0">
      <main class="novidades">
        <section class="span-2">
          <p class="titulo">
            <span class="material-symbols-outlined">query_stats</span>Consultas
            de Box
          </p>
          <p>
            Completamente repaginada e disponível agora em forma de
            "assistente", com os seguintes produtos disponíveis:
          </p>
          <ul>
            <li>Qualifica Endereço - PF / PJ</li>
            <li>Perfil de Negócios - PF / PJ</li>
            <li>Relações Societárias - PF / PJ</li>
            <li>Conjuntura Social - PF</li>
            <li>Anti-Fraude - PF</li>
            <li>Qualifica Geo - Indicadores Macro-Econômicos</li>
          </ul>
          <br />
          Para acessar esta funcionalidade,
          <router-link to="/consultas/box/0">[clique aqui]</router-link> ou
          navegue pelo menu "Consultas" / "Nova"
        </section>
        <section>
          <p class="titulo">
            <span class="material-symbols-outlined">bolt</span>Resultados
          </p>
          Página de resultado da consulta completamente reestruturada, com
          gráficos, indicadores visuais e mapas quando disponíveis.
        </section>
        <section>
          <p class="titulo">
            <span class="material-symbols-outlined">timeline</span> Histórico
          </p>
          Histórico reestruturado com acesso a todas as consultas realizadas no
          portal. Agora as consultas são agrupadas de acordo com o seu contexto
          inicial de execução, permitindo que o usuário acesse uma foto de uma
          consulta realizada no passado.<br /><br />
          Para acessar esta funcionalidade,
          <router-link to="/consultas/historico"> [clique aqui]</router-link>
          ou navegue pelo menu "Consultas"/"Histórico"
        </section>

        <section>
          <p class="titulo">
            <span class="material-symbols-outlined">cached</span> Consultas
            Cíclicas
          </p>
          <p>
            Novo conceito de consulta: a consulta cíclica permite que a partir
            de um parâmetro inicial sejam inferidos novos parâmetros para que a
            consulta atual possa ser aprofundada. Por exemplo, durante uma
            consulta Pessoa Jurídica, podem ser feitas consultas do tipo Geo,
            com base no CEP inferido para aquele CNPJ. <br />
          </p>
          <p>
            Para acessar esta funcionalidade verifique o link no cabeçalho de
            resultado de uma nova consulta do tipo "Pessoa Jurídica".
          </p>
        </section>

        <section style="border-bottom: 0">
          <p class="titulo">
            <span class="material-symbols-outlined">info</span> Alertas
          </p>
          <p>
            Novo conceito de produtos: Boxes de Alertas. Agora junto com os
            resultados de uma consulta são exibidos, quando existirem, diversos
            alertas positivos, negativos ou neutros ligados aos parâmetros
            consultados. Também é possível realizar consultas ligadas ao
            contexto de cada alerta, diretamente na tela de resultados.
          </p>
          <p>
            Para acessar esta funcionalidade verifique a sessão "Alertas",
            disponível no cabeçalho do resultado de uma nova consulta.
          </p>
        </section>
      </main>
    </Dropdown>
  </div>
</template>

<script>
import { chatOutboxStore } from "@/stores/chat-Outbox.js";
import { mapWritableState, mapActions } from "pinia";
import Dropdown from "@/components/Visuais/Dropdown.vue";
export default {
  components: {
    Dropdown,
  },

  methods: {
    ...mapActions(chatOutboxStore, ["recebeContexto"]),
  },

  mounted() {
    const contexto = {
      codigo_agrupador: "",
      rota: this.$route.path,
      nome: this.$route.name,
      descricao: "",
    };

    this.recebeContexto(contexto);
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

.page {
  padding: 0 1rem;
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  overflow: auto;
}
.novidades {
  overflow: auto;
  min-height: 20vh;
  &--minima {
    height: auto;
    overflow: auto;
  }
  section {
    font-size: 0.9rem;
    padding: 0.5rem 0;
    border-bottom: 0.5px solid #2196f3;

    .titulo {
      font-size: 1.2rem;
      line-height: 1.5rem;
      font-weight: bold;
      margin-bottom: 1rem;

      display: flex;
      align-items: center;
      gap: 10px;

      @include mediaQueryMax(small) {
        flex-direction: column;
        align-items: center;
      }

      span {
        color: $accent;
      }
    }

    ul {
      padding-left: 2.5rem;
    }
  }
}

.logo {
  width: 100px;

  &.superbox {
    width: 35px;
  }
}

@include mediaQuery(medium) {
  .novidades {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;

    section {
      height: fit-content;
      border-bottom: none;
      padding: 1rem;
      font-weight: 400;
      margin-bottom: 1rem;
    }
  }
  .span-2 {
    grid-row-start: span 2;
  }
}

.cols {
  display: flex;
  margin-bottom: 15px;
}

.col {
  width: 50%;

  ul {
    padding-left: 0 !important;
  }
}
</style>
